import React from 'react'
import PropTypes from 'prop-types'
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Div from '../components/Div/Div.styles'
import MelhorDataCenter from '../components/MelhorDataCenter'
import MelhorColocation from '../components/MelhorColocation'
import Premiacao from '../components/Premiacao'
import PCIDSS from '../components/CertificadoPCIDSS'
import ISO27001 from '../components/CertificadoISO27001'
import ISO20000 from '../components/CertificadoISO20000'
import SOC2Tipo2 from '../components/CertificadoSOC2Tipo2'
import CertificadoISO27701 from '../components/CertificadoISO27701'
import CertificadoISO27017 from '../components/CertificadoISO27017'
import CertificadoISO27018 from '../components/CertificadoISO27018'
import CertificadoISO9001 from '../components/CertificadoISO9001'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledOurCertificates,
  header
} from '../components/styles/OurCertificates.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledOurCertificates}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <span>POR QUE A HOSTDIME?</span>
            <H4>Premiações e Certificados</H4>
          </Column>
        </Row>
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={['center', 'center', 'initial']}
          mt={[30, 50]}
        >
          <Column
            mb={[16]}
          > 
            <Div
              maxWidth={['100%', 328]}
            >
              <MelhorDataCenter />
            </Div>
          </Column>
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <MelhorColocation />
            </Div>
          </Column>
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <Premiacao />
            </Div>
          </Column>
        </Row>
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={['center', 'center', 'initial']}
        >
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <PCIDSS />
            </Div>
          </Column>
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <ISO27001 />
            </Div>
          </Column>
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <ISO20000 />
            </Div>
          </Column>
        </Row>
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={['center', 'center', 'initial']}
        >
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <CertificadoISO27701 />
            </Div>
          </Column>
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <CertificadoISO27017 />
            </Div>
          </Column>
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <CertificadoISO27018 />
            </Div>
          </Column>
        </Row>
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={['center', 'center', 'initial']}
        >
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <CertificadoISO9001 />
            </Div>
          </Column>
          <Column
            mb={[16]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <SOC2Tipo2 />
            </Div>
          </Column>
          <Column
            mb={[16]}
          >
            <Div
              display={['none', 'none', 'flex']}
              style={{
                width: 328
              }}
            />
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Certificados e premiações | HostDime Brasil'
        description='Construir um Data Center sinônimo de inovação e estrutura exige excelência em todos os detalhes. Confira nossos certificados e prêmios!'
      />
      <PageTemplate
        title='Premiações e Certificados'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
