import React, { Component } from 'react'
import ReactTextCollapse from 'react-text-collapse' 
import ArrowR from '@material-ui/icons/ArrowForward'
import { css } from '@emotion/core'
import Div from './Div/Div.styles'
import ds from '../theme'
import { mq } from './styles/Layout.styles'
import H4 from './H4'
import H5 from './H5'
import Paragraph from './Paragraph'
import Subtitle from './SubTitle2'

import { scrollAction } from './styles/InternalPage.styles'

const TEXT_COLLAPSE_OPTIONS = { 
  collapse: false, 
  collapseText: '+ Mais detalhes', 
  expandText: '- Ocultar detalhes', 
  minHeight: 0, 
  maxHeight: 345,
  marginTop: 54, 
  overflowY: 'hidden !important',
  textStyle: { 
    color: '#44474e', 
    fontSize: '14px' ,
    lineHeight: 1.71,
    marginTop: '54px',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer'
  }
}

const SOCTipo2 = ({
  licenceName,
  licenceDescription,
  licenceIso,
  licenceBenefits,
  benefitsDescription,
}) => {
  return (
    <div
      style={{
        padding: '50px 24px 24px 24px',
        backgroundColor: '#ffffff',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        // item 
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H4
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 50,
            width: 296,
            textAlign: 'center'
          }}
        >
          {licenceName}
        </H4>
        <Div
          style={{
            width: 200,
            height: 60,
            borderRadius: 30,
            border: 'solid 1px #44474e',
            justifyContent: 'center',
            marginBottom: 17
          }}
        >
          <Subtitle
            style={{
              fontSize: 14,
              fontWeight: 600,
              lineHeight: 1.5,
              letterSpacing: 2.38,
              textAlign: 'center',
              color: '#44474e',
              whiteSpace: 'pre-wrap'
            }}
          >
            {licenceIso}
          </Subtitle>
        </Div>
        <ReactTextCollapse
          options={TEXT_COLLAPSE_OPTIONS}
          css={scrollAction}
        >
          <Div 
            style={{
              width: 200,
              height: 1,
              borderBottom: 'solid 1px #e0e0e0',
              margin: '0 auto',
              marginTop: 20,
              marginBottom: 20
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'center',
              color: 'rgba(0, 0, 0, 0.6)'
            }}
          >
            {licenceDescription}
          </Paragraph>
          <Div 
            style={{
              width: 200,
              height: 1,
              borderBottom: 'solid 1px #e0e0e0',
              margin: '0 auto',
              marginTop: 20,
              marginBottom: 20
            }}
          />
          <H5 
            style={{
              fontSize: 23.7,
              textAlign: 'center',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)',
              marginTop: 10,
              marginBottom: 24,
              whiteSpace: 'pre-wrap'
            }}>
            {licenceBenefits}
          </H5>
          <Div
            style={{
              display: '-webkit-box'
            }}
          >
            <ArrowR 
              style={{
                color: 'rgba(78, 81, 89, 0.54)'
              }}
            />
            <Paragraph
              style={{
                fontSize: 14,
                lineHeight: 1.7,
                textAlign: 'left',
                width: 240,
                marginLeft: 16,
                color: 'rgba(0, 0, 0, 0.6)'
              }}
            >
              {benefitsDescription}
            </Paragraph>
          </Div>
        </ReactTextCollapse>
      </Div>
    </div>
  )
}

class CertificadoSOCTipo2 extends Component {
  render () {
    return (
      <div>
        <SOCTipo2
          licenceName='Certificado'
          licenceIso={`SOC 2 Tipo 2`}
          licenceDescription='A certificação SOC 2 Tipo 2 garante rigorosos e abrangentes controles de segurança da informação pela organização.'
          licenceBenefits={`Diferenciais`}
          benefitsDescription='Uma organização que obtém a certificação SOC 2 Tipo 2 provou que seu sistema foi feito para manter os dados sensíveis de seus clientes de forma segura.'
        />
      </div>
    )
  }
}

export default CertificadoSOCTipo2