import React, { Component } from 'react'
import ReactTextCollapse from 'react-text-collapse' 
import ArrowR from '@material-ui/icons/ArrowForward'
import { css } from '@emotion/core'
import Div from '../components/Div/Div.styles'
import ds from '../theme'
import { mq } from '../components/styles/Layout.styles'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import Subtitle from '../components/SubTitle2'

import { scrollAction } from '../components/styles/InternalPage.styles'

const TEXT_COLLAPSE_OPTIONS = { 
  collapse: false, 
  collapseText: '+ Mais detalhes', 
  expandText: '- Ocultar detalhes', 
  minHeight: 0, 
  maxHeight: 760,
  marginTop: 54, 
  overflow: 'hidden !important',
  textStyle: { 
    color: '#f9fafa', 
    fontSize: '14px' ,
    lineHeight: 1.71,
    marginTop: '54px',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer'
  }
}

const Awards2 = ({
  licenceName,
  licenceDescription,
  licenceDescription2,
  licenceIso,
  licenceBenefits,
  benefitsDescription,
  benefitsDescriptionItem2,
  benefitsDescriptionItem3,
  benefitsDescriptionItem4,
}) => {
  return (
    <div
      style={{
        padding: '50px 24px 24px 24px',
        backgroundColor: '#4e5159',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12,
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        // item 
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H4
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 16,
            width: 296,
            textAlign: 'center',
            color: '#f9fafa',
            whiteSpace: 'pre-wrap'
          }}
        >
          {licenceName}
        </H4>
        <Div
          style={{
            width: 200,
            height: 60,
            borderRadius: 30,
            border: 'solid 1px #fff',
            justifyContent: 'center',
            marginBottom: 10
          }}
        >
          <Subtitle
            style={{
              fontSize: 14,
              fontWeight: 600,
              lineHeight: 1.5,
              letterSpacing: 2.38,
              textAlign: 'center',
              color: '#f9fafa',
              whiteSpace: 'pre-wrap'
            }}
          >
            {licenceIso}
          </Subtitle>
        </Div>
        <ReactTextCollapse
          options={TEXT_COLLAPSE_OPTIONS}
          css={scrollAction}
        >
          <Div 
            style={{
              width: 200,
              height: 1,
              borderBottom: 'solid 1px #e0e0e0',
              margin: '0 auto',
              marginTop: 20,
              marginBottom: 20
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'center',
              color: '#f9fafa',
              marginBottom: 16
            }}
          >
            {licenceDescription}
          </Paragraph>
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'center',
              color: '#f9fafa'
            }}
          >
            {licenceDescription2}
          </Paragraph>
          <Div 
            style={{
              width: 200,
              height: 1,
              borderBottom: 'solid 1px #e0e0e0',
              margin: '0 auto',
              marginTop: 20,
              marginBottom: 20
            }}
          />
          <H5 
            style={{
              fontSize: 23.7,
              textAlign: 'center',
              fontWeight: 500,
              color: '#f9fafa',
              marginTop: 10,
              marginBottom: 24,
              whiteSpace: 'pre-wrap'
            }}>
            {licenceBenefits}
          </H5>
          <Div
            style={{
              display: '-webkit-box',
              marginBottom: 16
            }}
          >
            <ArrowR 
              style={{
                color: 'rgba(255, 255, 255, 0.54)'
              }}
            />
            <Paragraph
              style={{
                fontSize: 14,
                lineHeight: 1.7,
                textAlign: 'left',
                width: 240,
                marginLeft: 16,
                color: '#f9fafa'
              }}
            >
              {benefitsDescription}
            </Paragraph>
          </Div>
          <Div
            style={{
              display: '-webkit-box',
              marginBottom: 16
            }}
          >
            <ArrowR 
              style={{
                color: 'rgba(255, 255, 255, 0.54)'
              }}
            />
            <Paragraph
              style={{
                fontSize: 14,
                lineHeight: 1.7,
                textAlign: 'left',
                width: 240,
                marginLeft: 16,
                color: '#f9fafa'
              }}
            >
              {benefitsDescriptionItem2}
            </Paragraph>
          </Div>
          <Div
            style={{
              display: '-webkit-box',
              marginBottom: 16
            }}
          >
            <ArrowR 
              style={{
                color: 'rgba(255, 255, 255, 0.54)'
              }}
            />
            <Paragraph
              style={{
                fontSize: 14,
                lineHeight: 1.7,
                textAlign: 'left',
                width: 240,
                marginLeft: 16,
                color: '#f9fafa'
              }}
            >
              {benefitsDescriptionItem3}
            </Paragraph>
          </Div>
          <Div
            style={{
              display: '-webkit-box',
            }}
          >
            <ArrowR 
              style={{
                color: 'rgba(255, 255, 255, 0.54)'
              }}
            />
            <Paragraph
              style={{
                fontSize: 14,
                lineHeight: 1.7,
                textAlign: 'left',
                width: 240,
                marginLeft: 16,
                color: '#f9fafa'
              }}
            >
              {benefitsDescriptionItem4}
            </Paragraph>
          </Div>
        </ReactTextCollapse>
      </Div>
    </div>
  )
}

class MelhorColocation extends Component {
  render () {
    return (
      <div>
        <Awards2
          licenceName={`Melhor\n Colocation`}
          licenceIso={`DCD AWARDS`}
          licenceDescription='Melhor Fornecedor de Colocation em 2017 e 2018;'
          licenceDescription2='Por dois anos consecutivos, fomos premiados como melhor fornecedor de Colocation, por
          premiação concedida pelo principal veículo de comunicação do segmento, a editora e
          empresa global de meios de comunicação B2B, Data Center Dynamics, analisando a
          estrutura dos Data Centers da América Latina.'
          licenceBenefits={`Diferenciais`}
          benefitsDescription='Atestado de qualidade, confiabilidade e força de um dos nossos principais produtos,
          o serviço de Colocation;'
          benefitsDescriptionItem2='Fortalecimento da importância da inovação no setor;'
          benefitsDescriptionItem3='Reconhecimento pelos profissionais e jurados que atuam no setor com a força
          disruptiva de nossa estrutura e serviços;'
          benefitsDescriptionItem4='Consolidação da HostDime Brasil como um dos melhores Data Centers da América
          Latina.'
        />
      </div>
    )
  }
}

export default MelhorColocation